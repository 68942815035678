import { Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { differenceInDays } from 'date-fns';

import { PortfolioDashboardApi } from '../../api/v1/bespoke/PortfolioDashboardApi';
import { Content, InternalLayout, LoadingIndicator, MessageCard } from '../../components';
import { PortfolioDashboardQueryKeys, QueryTopics } from '../../constants/queries';
import usePermissions from '../../hooks/usePermissions';
import PortfolioDashboardPage from './PortfolioDashboardPage';

const CUTOFF_NUM_DAYS = 1095; // 3 years in days

const PortfolioDashboardContainer = () => {
  const projectsQuery = useQuery({
    queryKey: [QueryTopics.PORTFOLIO_DASHBOARD, PortfolioDashboardQueryKeys.PORTFOLIO_PROJECTS_LIST],
    queryFn: async ({ signal }) => (await PortfolioDashboardApi.listProjects({ signal })).data,
  });

  const { canInviteUsers } = usePermissions();

  const isFetching = projectsQuery.isFetching;
  const isError = projectsQuery.isError;

  // TODO: Remove this once we have a proper way to flag "bad" forecast dates on the backend
  // This is a temporary bandaid fix to hide forecast dates that are more than 3 years from now and it makes me sad
  const processedProjects =
    projectsQuery.data?.map((project) => ({
      ...project,
      forecast_completion_date: project.forecast_completion_date
        ? differenceInDays(new Date(project.forecast_completion_date), new Date()) > CUTOFF_NUM_DAYS
          ? null
          : project.forecast_completion_date
        : null,
    })) ?? [];

  if (isFetching) {
    return (
      <InternalLayout>
        <LoadingIndicator fullPage />
      </InternalLayout>
    );
  }

  if (isError || !projectsQuery.data) {
    return (
      <InternalLayout>
        <Content applyGutters>
          <MessageCard title="Failed to load projects">
            <Text>
              An error occurred. Please try again later. If this issue persists, contact our Customer Success team at{' '}
              <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a>.
            </Text>
          </MessageCard>
        </Content>
      </InternalLayout>
    );
  }

  if (projectsQuery.data.length === 0) {
    return (
      <InternalLayout>
        <Content applyGutters>
          <MessageCard title="No Projects Found">
            <Text>
              We have not found any projects associated with your account. Please contact our Customer Success team at{' '}
              <a href="mailto:customersuccess@onsiteiq.io">customersuccess@onsiteiq.io</a>.
            </Text>
          </MessageCard>
        </Content>
      </InternalLayout>
    );
  }

  return (
    <InternalLayout>
      <Content applyGutters constrainToPageHeight>
        <PortfolioDashboardPage canInviteUsers={canInviteUsers} projects={processedProjects} />
      </Content>
    </InternalLayout>
  );
};

export default PortfolioDashboardContainer;
