import { differenceInDays } from 'date-fns';

export const getVarianceInDays = (baselineCompletionDate: string | null, forecastCompletionDate: string | null) => {
  if (!baselineCompletionDate || !forecastCompletionDate) {
    return null;
  }

  const baselineCompletionDateObj = new Date(baselineCompletionDate);
  const forecastCompletionDateObj = new Date(forecastCompletionDate);

  if (isNaN(baselineCompletionDateObj.getTime()) || isNaN(forecastCompletionDateObj.getTime())) {
    return null;
  }

  const difference = differenceInDays(forecastCompletionDateObj, baselineCompletionDateObj);
  return difference > 0 ? `+${difference}` : difference;
};
